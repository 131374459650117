import "bootstrap/dist/css/bootstrap.min.css";
import 'react-toastify/dist/ReactToastify.css';

import { BrowserRouter, Route, Navigate, Routes } from "react-router-dom";
import './App.css';
import React, { lazy, Suspense } from 'react';
import ReactGA from 'react-ga4';
import MostAction from "./pages/MostAction";
import MostAdventure from "./pages/MostAdventure";
import MostSports from "./pages/MostSports";
import MostRacing from "./pages/MostRacing";
import MostStrategy from "./pages/MostStrategy";
import MostPuzzle from "./pages/MostPuzzle";
import MostCasual from "./pages/MostCasual";
import MostMemory from "./pages/MostMemory";
import MostTwoPlayer from "./pages/MostTwoPlayer";
import NewArrivalAction from "./pages/NewArrivalAction";
import NewArrivalSports from "./pages/NewArrivalSports";
import NewArrivalAdventure from "./pages/NewArrivalAdventure";
import NewArrivalRacing from "./pages/NewArrivalRacing";
import NewArrivalStrategy from "./pages/NewArrivalStrategy";
import NewArrivalPuzzle from "./pages/NewArrivalPuzzle";
import NewArrivalCasual from "./pages/NewArrivalCasual";
import NewArrivalMemory from "./pages/NewArrivalMemory";
import NewArrivalTwoPlayer from "./pages/NewArrivalTwoPlayer";
import LoginOtp from "./pages/Login-Otp";
import Home from "./pages/Home"
import Spinner from "./components/Spinner";

const AuthenticatedHome = lazy(() => import('./pages/AuthenticatedHome'));
const AuthenticatedGames = lazy(() => import('./pages/AuthenticatedGames'));
const LoginPage = lazy(() => import('./pages/LoginPage'));
const OTPPage = lazy(() => import('./pages/OTPPage'));
const ComponentToDisplay = lazy(() => import('./components/ComponentToDisplay'));
const UnsubscribePage = lazy(() => import('./pages/UnsubscribePage'));
const AuthenticatedGamesAll = lazy(() => import('./pages/AuthenticatedGamesAll'));
const ComponentToDisplayAllGames = lazy(() => import('./components/ComponentToDisplayAllGames'));
const Action = lazy(() => import('./pages/Action'));
const Adventure = lazy(() => import('./pages/Adventure'));
const Racing = lazy(() => import('./pages/Racing'));
const Sports = lazy(() => import('./pages/Sports'));
const Strategy = lazy(() => import('./pages/Strategy'));
const Favorites = lazy(() => import('./pages/Favorites'));
const Puzzle = lazy(() => import('./pages/Puzzle'));
const Casual = lazy(() => import('./pages/Casual'));
const Memory = lazy(() => import('./pages/Memory'));
const TwoPlayer = lazy(() => import('./pages/TwoPlayer'));
const MostPopular = lazy(() => import('./pages/MostPopular'));
const NewArrivals = lazy(() => import('./pages/NewArrivals'));
const DownloadGames = lazy(() => import('./pages/DownloadGames'))
const PrivacyPolicy = lazy(() => import('./pages/Policy'))
const ProfileView = lazy(() => import('./pages/ProfileView'));

ReactGA.initialize("G-48E603Q673");

function App() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const uniqRef = urlParams.get('uniqref');

    const PrivateRoute = ({ element }) => {
        return localStorage.getItem('jwt_z') ? element : <Navigate to="/index.php" />;
    };

    ReactGA._gaCommandSendPageview(window.location.pathname)
    return (
        <BrowserRouter>


            <Suspense fallback={<Spinner />}>
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/home" element={<PrivateRoute element={<AuthenticatedHome />} />} />
                    <Route path="/game-component" element={<PrivateRoute element={<ComponentToDisplay />} />} />
                    <Route path="/game-component1" element={<PrivateRoute element={<ComponentToDisplayAllGames />} />} />
                    <Route path="/games" element={<PrivateRoute element={<AuthenticatedGames />} />} />
                    <Route path="/games-all" element={<PrivateRoute element={<AuthenticatedGamesAll />} />} />
                    <Route path="/strategy" element={<PrivateRoute element={<Strategy />} />} />
                    <Route path="/sports" element={<PrivateRoute element={<Sports />} />} />
                    <Route path="/racing" element={<PrivateRoute element={<Racing />} />} />
                    <Route path="/adventure" element={<PrivateRoute element={<Adventure />} />} />
                    <Route path="/action" element={<PrivateRoute element={<Action />} />} />
                    <Route path="/puzzle" element={<PrivateRoute element={<Puzzle />} />} />
                    <Route path="/casual" element={<PrivateRoute element={<Casual />} />} />
                    <Route path="/memory" element={<PrivateRoute element={<Memory />} />} />
                    <Route path="/twoplayer" element={<PrivateRoute element={<TwoPlayer />} />} />
                    <Route path="/favorites" element={<PrivateRoute element={<Favorites />} />} />
                    <Route path="/mostpopular" element={<PrivateRoute element={<MostPopular />} />} />
                    <Route path="/newarrivals" element={<PrivateRoute element={<NewArrivals />} />} />
                    <Route path="/mostaction" element={<PrivateRoute element={<MostAction />} />} />
                    <Route path="/mostsports" element={<PrivateRoute element={<MostSports />} />} />
                    <Route path="/mostadventure" element={<PrivateRoute element={<MostAdventure />} />} />
                    <Route path="/mostracing" element={<PrivateRoute element={<MostRacing />} />} />
                    <Route path="/moststrategy" element={<PrivateRoute element={<MostStrategy />} />} />
                    <Route path="/mostpuzzle" element={<PrivateRoute element={<MostPuzzle />} />} />
                    <Route path="/mostcasual" element={<PrivateRoute element={<MostCasual />} />} />
                    <Route path="/mostmemory" element={<PrivateRoute element={<MostMemory />} />} />
                    <Route path="/mosttwoplayer" element={<PrivateRoute element={<MostTwoPlayer />} />} />
                    <Route path="/newarrivalaction" element={<PrivateRoute element={<NewArrivalAction />} />} />
                    <Route path="/newarrivalsport" element={<PrivateRoute element={<NewArrivalSports />} />} />
                    <Route path="/newarrivaladventure" element={<PrivateRoute element={<NewArrivalAdventure />} />} />
                    <Route path="/newarrivalracing" element={<PrivateRoute element={<NewArrivalRacing />} />} />
                    <Route path="/newarrivalstrategy" element={<PrivateRoute element={<NewArrivalStrategy />} />} />
                    <Route path="/newarrivalpuzzle" element={<PrivateRoute element={<NewArrivalPuzzle />} />} />
                    <Route path="/newarrivalcasual" element={<PrivateRoute element={<NewArrivalCasual />} />} />
                    <Route path="/newarrivalmemory" element={<PrivateRoute element={<NewArrivalMemory />} />} />
                    <Route path="/newarrivaltwoplayer" element={<PrivateRoute element={<NewArrivalTwoPlayer />} />} />
                    <Route path="/profile-view" element={<PrivateRoute element={<ProfileView />} />} />
                    <Route path="/downloadnow" element={<PrivateRoute element={<DownloadGames />} />} />
                    <Route path="/index.php" element={<LoginPage />} />
                    <Route path="/otphandler" element={<OTPPage />} />
                    <Route path="/otphandler-he" element={<OTPPage />} />
                    <Route path="/loginandotp" element={<LoginOtp />} />
                    <Route path="/unsubscribe" element={<UnsubscribePage />} />
                    <Route path="/policy" element={<PrivacyPolicy />} />
                    <Route path="noetic_zong_ads.php" element={<Navigate to={`/index.php?vinfo=${uniqRef}:noetic_zong_ads`} />} />
                    <Route path="/2m1i.php" element={<Navigate to={`/index.php?vinfo=${uniqRef}:2m1i`} />} />
                    <Route path="/2m3i.php" element={<Navigate to={`/index.php?vinfo=${uniqRef}:2m3i`} />} />
                    <Route path="/2m4i.php" element={<Navigate to={`/index.php?vinfo=${uniqRef}:2m4i`} />} />
                    <Route path="/2m5i.php" element={<Navigate to={`/index.php?vinfo=${uniqRef}:2m5i`} />} />
                    <Route path="/2m6i.php" element={<Navigate to={`/index.php?vinfo=${uniqRef}:2m6i`} />} />
                    <Route path="/2m15i.php" element={<Navigate to={`/index.php?vinfo=${uniqRef}:2m15i`} />} />
                    <Route path="/2m7i.php" element={<Navigate to={`/index.php?vinfo=${uniqRef}:2m7i`} />} />
                    <Route path="/4m20i.php" element={<Navigate to={`/index.php?vinfo=${uniqRef}:4m20i`} />} />
                    <Route path="/2m9i.php" element={<Navigate to={`/index.php?vinfo=${uniqRef}:2m9i`} />} />
                    <Route path="/4m51i.php" element={<Navigate to={`/index.php?vinfo=${uniqRef}:4m51i`} />} />
                    <Route path="/2m13i.php" element={<Navigate to={`/index.php?vinfo=${uniqRef}:2m13i`} />} />
                    <Route path="/2m14i.php" element={<Navigate to={`/index.php?vinfo=${uniqRef}:2m14i`} />} />
                    <Route path="/4m32i.php" element={<Navigate to={`/index.php?vinfo=${uniqRef}:4m32i`} />} />
                    <Route path="/noetic_google_ads.php" element={<Navigate to={`/index.php?vinfo=${uniqRef}:noetic_google_ads`} />} />
                    <Route path="/mobipium.php" element={<Navigate to={`/index.php?vinfo=${uniqRef}:mobipium`} />} />
                    <Route path="" element={<Navigate to={`/index.php`} />} />

                </Routes>
            </Suspense>
        </BrowserRouter>
    );
}

export default App;
